import React from "react";
import Slider from "react-slick";
import { valueArr } from "../../constants/data";
import Card from "./Card";
import "../../css/carousel.css";

const SliderSection = ({ width }) => {
  var settings = {
    infinite: true,
    centerMode: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  var settings1 = {
    infinite: true,
    centerMode: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  return (
    <>
      <Slider {...(width > 700 ? settings : settings1)}>
        {valueArr.map((cardData, idx) => {
          return (
            <div
              className="value_card"
              id={`value_card_${idx}`}
              key={cardData + idx}
            >
              <Card
                title={cardData.title}
                title_des={cardData.title_des}
              ></Card>
            </div>
          );
        })}
      </Slider>
    </>
  );
};

export default SliderSection;
