import "../../css/efficiency.css";
import React, { useEffect } from "react";

import { useNavigate } from "react-router";
import SliderSection from "./SliderSection";
import Teams from "./Teams";
import { Metadata } from "../Metadata/Metadata";

const About1 = ({ width }) => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0, { behavior: "smooth" });
  }, []);
  return (
    <div className="e_main_container root">
      <Metadata
        title={"About us | Autrob - An Innovation & Invention Company"}
      ></Metadata>
      <div className="parent">
        <div className="child-1">
          <div>
            <img
              onClick={() => navigate(-1)}
              style={{
                margin: width > 700 ? "0vw 1vw 1vw 0vw" : "0vw 1vw 2vw 0vw",
                height: width > 700 ? "3vw" : "8vw",
                width: width > 700 ? "3vw" : "8vw",
                objectFit: "contain",
                cursor: "pointer",
              }}
              src={"assests/image/93634.png"}
              alt=""
            />
          </div>
          <div className="about-us-div">ABOUT US</div>
          <div
            style={{
              marginTop: "2vw",
            }}
            className="child-1_firstheading"
          >
            <p
              style={{
                letterSpacing: "-1px",
                lineHeight: "34px",
                fontSize: width > 700 ? "2.5vw" : "6vw",
                fontWeight: "400",
                fontFamily: "Poppins",
              }}
            >
              Who We Are?
            </p>
          </div>
          <div
            style={{
              color: "#000000",
              marginTop: "1.3vw",
              fontSize: width > 700 ? "1.2vw" : "3.3vw",
              // width: "86%",
              fontWeight: "500",
              lineHeight: width > 700 ? "2vw" : "5vw",
              fontFamily: "Poppins",
            }}
            className="child-1_secondheading"
          >
            <p>
              As an Innovation & Invention Company ,we believe in technology as
              a force for good. We always try to solve human error with the
              technological solutions
            </p>
          </div>

          <div className="list">
            <ul>
              <li>
                Our team consists of talented professionals who bring diverse
                expertise and a shared vision of creating a better future
                through technology. We believe that every challenge presents an
                opportunity for innovation, and we embrace the spirit of
                exploration and creativity in all our endeavors.
              </li>
            </ul>
          </div>
        </div>
        <div className="child-2">
          <div className="about_image">
            <img src="assests/edit/How We are.jpg" alt=""></img>
          </div>
          <div className="second-box">
            <div className="second-box-child1">
              Start Growing With Community & Experience Endless Possibilities
            </div>
            <div className="second-box-child2">
              <div className="profile_img">
                <img src="assests/image/team1 1.png" alt=""></img>
              </div>
              <div className="profile_des">
                <div className="profile_name" style={{ fontWeight: "700" }}>
                  Manish Kumar
                </div>
                <div className="profile_deg">Founder</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section style={{ padding: "1.5vw", paddingBottom: "0vw" }}>
        <div
          style={{
            marginTop: width > 700 ? "2vw" : "10vw",
            marginBottom: width > 700 ? "0vw" : "3vw",
            width: "50vw",
            fontWweight: "200",
          }}
        >
          <p
            style={{
              letterSpacing: "-1px",
              lineHeight: width > 700 ? "4vw" : "8vw",
              fontSize: width > 700 ? "2.4vw" : "6vw",
              fontWeight: "500px",
              fontFamily: "Poppins",
            }}
          >
            Our Values
          </p>
        </div>
        <div className="value_cards">
          <SliderSection width={width} />
        </div>
      </section>
      <section
        style={{ padding: "0.5vw", paddingTop: "0vw", marginBottom: "1vw" }}
      >
        <div
          style={{
            width: "49vw",
            fontWweight: "200",
          }}
        >
          <p
            style={{
              letterSpacing: "-1px",
              lineHeight: width > 700 ? "4vw" : "8vw",
              fontSize: width > 700 ? "3vw" : "6vw",
              fontWeight: "400px",
              fontFamily: "Poppins",
            }}
          >
            Meet Our Team
          </p>
        </div>
        <div
          style={{
            fontFamily: "Poppins",
            fontWeight: "400",
            fontSize: width > 700 ? " 1.3vw" : "3.5vw",
            lineHeight: width > 700 ? "1.5vw" : "4.5vw",
            color: "#000000",
            margin: "2vw 0vw",
            marginTop: "1vw",
          }}
        >
          We’re a growing team of technological experts and thought leaders
        </div>
        <Teams width={width} />
      </section>
    </div>
  );
};

export default About1;
