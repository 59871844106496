import React from "react";
import { useNavigate } from "react-router";
import { detailsArr } from "../../constants/data";
import { Metadata } from "../Metadata/Metadata";

const ResourceUpperSection = ({ width }) => {
  const navigate = useNavigate();
  return (
    <>
       <Metadata

        title={"Hydroponic Automation| Autrob - An Innovation & Invention Company"}
      ></Metadata>
      <section>
        <div className="resourceuppersection">
          <div className="resource_text-section">
            <div>
              <img
                onClick={() => navigate(-1)}
                style={{
                  margin: width > 700 ? "0vw 1vw 1vw 0vw" : "0vw 1vw 2vw 0vw",
                  height: width > 700 ? "3vw" : "8vw",
                  width: width > 700 ? "3vw" : "8vw",
                  objectFit: "contain",
                  cursor: "pointer",
                }}
                src={"assests/image/93634.png"}
                alt=""
              />
            </div>
            <h1>Hydroponic Automation</h1>
            <p>
              Hydroponic automation refers to the use of advanced technology and
              systems to automate various processes in hydroponic farming, where
              plants are grown in nutrient-rich water instead of soil. It
              involves the integration of sensors, controllers, and software to
              monitor and regulate essential elements such as water, nutrients,
              pH levels, lighting, and climate conditions in hydroponic setups.
            </p>
          </div>
          <div className="grey_box-container">
            <img
              src={"assests/image/Cover (1).png"}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",

                borderRadius: "5%",
              }}
              alt=""
            />
          </div>
        </div>
      </section>
      <section hidden>
        <div className="resourcemiddlesection">
          {detailsArr.map((item) => (
            <div className="resourcemiddlesection-child">
              <div className="heading-no">{item.no}</div>
              <div className="text">{item.text}</div>
            </div>
          ))}
        </div>
      </section>

      {/* Image */}
      <section>
        <div className="middle-image">
          <img src="assests/image/pexels-anna-tarazevich-7299967.jpg" alt="" />
        </div>
      </section>

      <section hidden>
        <div className="resourcemiddlesection">
          {detailsArr.map((item) => (
            <div className="resourcemiddlesection-child">
              <div className="heading-no">{item.no}</div>
              <div className="text">{item.text}</div>
            </div>
          ))}
        </div>
      </section>
      <section>
        <div className="modal-section">
          <h1>Benefits</h1>
        </div>
        <div
          style={{ marginTop: "1vw", marginBottom: "3vw" }}
          className="modal-section"
        >
          <ul style={{ listStyle: "disc", marginLeft: "1.5vw" }}>
            <li>
              <div>
                <strong>Precise Nutrient and Water Management: </strong>{" "}
                Automation systems accurately monitor and control the nutrient
                solution levels, ensuring plants receive optimal nutrition and
                water supply. This leads to healthier plant growth, higher
                yields, and efficient resource utilization.
              </div>
            </li>
            <li>
              <div>
                <strong>pH and EC Regulation:</strong> Automated systems
                maintain the ideal pH and electrical conductivity (EC) levels in
                the nutrient solution, creating an optimal environment for plant
                growth. This helps prevent nutrient imbalances and ensures
                nutrient absorption by plants.
              </div>
            </li>
            <li>
              <div>
                <strong>Climate Control:</strong> Hydroponic automation allows
                precise regulation of temperature, humidity, and ventilation.
                This ensures that plants are provided with optimal growing
                conditions, regardless of external weather variations, resulting
                in consistent crop quality and yield.
              </div>
            </li>
            <li>
              <div>
                <strong>Light Control:</strong> Automated lighting systems
                enable the precise adjustment of light intensity, duration, and
                spectrum according to plant requirements. This promotes healthy
                photosynthesis, optimal growth, and improved energy efficiency.
              </div>
            </li>
            <li>
              <div>
                <strong>Water Conservation:</strong> Automation systems optimize
                water usage by monitoring and controlling irrigation cycles
                based on plant needs. This reduces water waste, making
                hydroponics a more environmentally friendly farming method
                compared to traditional soil-based agriculture.
              </div>
            </li>
            <li>
              <div>
                <strong>Reduced Labor and Increased Efficiency:</strong>
                Automated hydroponic systems minimize manual intervention,
                reducing the need for constant monitoring and adjustment. This
                saves labor time and enables growers to focus on other critical
                aspects of crop management, leading to increased operational
                efficiency.
              </div>
            </li>
            <li>
              <div>
                <strong>Data Monitoring and Analysis:</strong> Automation
                systems collect real-time data on environmental parameters,
                nutrient levels, and plant health. This data can be analyzed to
                gain valuable insights, optimize growing conditions, identify
                trends, and make data-driven decisions for crop management.
              </div>
            </li>
            <li>
              <div>
                <strong>Disease and Pest Prevention:</strong> Automation systems
                can integrate pest and disease management mechanisms such as
                sensors, alarms, and automated treatments. This helps detect and
                prevent issues early, reducing crop damage and the need for
                chemical interventions.
              </div>
            </li>
            <li>
              <div>
                <strong>Scalability and Consistency:</strong>Automation systems
                are scalable, allowing growers to expand their operations
                without compromising quality or consistency. The automation
                technology ensures uniformity in plant growth and crop
                production, making it suitable for commercial hydroponic
                farming.
              </div>
            </li>
            <li>
              <div>
                <strong>Year-round Cultivation:</strong> Hydroponic automation
                enables year-round cultivation, regardless of external seasonal
                conditions. This provides growers with the opportunity to have a
                continuous and reliable supply of fresh produce throughout the
                year.
              </div>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default ResourceUpperSection;
