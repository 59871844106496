import React from "react";

const Teams = ({width}) => {
  return (
    <>
      <div className="Tmain_container">
        <div className="container">
          <div className="image_container">
            <img src="assests/image/team1 1.png" alt=""></img>
          </div>
          <div className="team_member_name">Manish Kumar</div>
          <div className="team_member_deg">Founder & Managing Director</div>
        </div>
        <div className="container">
          <div className="image_container">
            <img src="assests/image/Untitled design (2) 1.png" alt=""></img>
          </div>
          <div className="team_member_name">Kunaal Arora</div>
          <div className="team_member_deg">Finance Director</div>
        </div>
        <div className="container">
          <div className="image_container">
            <img src={"assests/Team/1.e82183bcc92bbe0b1f1a.png"} alt=""></img>
          </div>
          <div className="team_member_name">Rahul Rajput</div>
          <div className="team_member_deg">Full-Stack Developer</div>
        </div>
        <div className="container">
          <div className="image_container">
            <img src="assests/Team/7.a3e19224dc554702a10c.png" alt=""></img>
          </div>
          <div className="team_member_name">Saurabh</div>
          <div className="team_member_deg">Backend Developer</div>
        </div>
        <div className="container">
          <div className="image_container">
            <img src="assests/Team/4.0d55d07b6f3621b872bd.png" alt=""></img>
          </div>
          <div className="team_member_name">Praveen Kumar</div>
          <div className="team_member_deg">UI/UX Designer</div>
        </div>
        <div className="container">
          <div className="image_container">
            <img src="assests/Team/10.4c563905c0881cd407ce.png" alt=""></img>
          </div>
          <div className="team_member_name">Tisha Singh</div>
          <div className="team_member_deg">Co-Ordinator</div>
        </div>
        <div className="container">
          <div className="image_container">
            <img src="assests/Team/9.5ccc60a3018743096075.png" alt=""></img>
          </div>
          <div className="team_member_name">Rinku Saini</div>
          <div className="team_member_deg">Human Resource</div>
        </div>
        <div className="container">
          <div className="image_container">
            <img src="assests/Team/5.c1f569987e5dd2b68fe5.png" alt=""></img>
          </div>
          <div className="team_member_name">Mohit Mangal</div>
          <div className="team_member_deg">Designer</div>
        </div>
        <div className="container">
          <div className="image_container">
            <img src="assests/Team/6.0216e4ed7b84868a3831.png" alt=""></img>
          </div>
          <div className="team_member_name">Aanchal</div>
          <div className="team_member_deg">Legal</div>
        </div>
        <div className="container">
          <div className="image_container">
            <img src="assests/Team/8.a8b1a6837d5c3338d24e.png" alt=""></img>
          </div>
          <div className="team_member_name">Umesh Daukiya</div>
          <div className="team_member_deg">Digital Marketing</div>
        </div>
      </div>
    </>
  );
};

export default Teams;
