import React from "react";
import { useNavigate } from "react-router";
import { detailsArr } from "../../constants/data";
import { Metadata } from "../Metadata/Metadata";

const ResourceUpperSection = ({ width }) => {
  const navigate = useNavigate();
  return (
    <>
      <Metadata
        title={"Autrob Coding Course | Autrob - An Innovation & Invention Company"}
      ></Metadata>
      <section>
        <div className="resourceuppersection">
          <div className="resource_text-section">
            <div>
              <img
                onClick={() => navigate(-1)}
                style={{
                  margin: width > 700 ? "0vw 1vw 1vw 0vw" : "0vw 1vw 2vw 0vw",
                  height: width > 700 ? "3vw" : "8vw",
                  width: width > 700 ? "3vw" : "8vw",
                  objectFit: "contain",
                  cursor: "pointer",
                }}
                src={"assests/image/93634.png"}
                alt=""
              />
            </div>
            <h1>Autrob Coding Course </h1>
            <p>
              Become a Full Stack Developer: Unleash Your Potential in the World
              of Technology Are you passionate about creating innovative web
              applications, mastering the latest technologies, and taking your
              career to new heights? Becoming a Full Stack Developer might be
              the perfect path for you!
            </p>
          </div>
          <div
            style={{
              height: width > 700 ? "27vw" : "59vw",
              maxHeight: width > 700 ? "27vw" : "59vw",

              borderRadius: "5%",
            }}
            className="grey_box-container"
          >
            <img
              src={"assests/course/2 (1).jpg"}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",

                borderRadius: "5%",
              }}
              alt=""
            />
          </div>
        </div>
      </section>
      <section hidden>
        <div className="resourcemiddlesection">
          {detailsArr.map((item) => (
            <div className="resourcemiddlesection-child">
              <div className="heading-no">{item.no}</div>
              <div className="text">{item.text}</div>
            </div>
          ))}
        </div>
      </section>

      {/* Image */}
      <section>
        <div className="middle-image">
          <img
            style={{ objectFit: "cover" }}
            src="assests/course/1 (1).jpg"
            alt=""
          />
        </div>
      </section>

      <section hidden>
        <div className="resourcemiddlesection">
          {detailsArr.map((item) => (
            <div className="resourcemiddlesection-child">
              <div className="heading-no">{item.no}</div>
              <div className="text">{item.text}</div>
            </div>
          ))}
        </div>
      </section>
      <section>
        <div className="modal-section">
          <h1>Course Highlights:</h1>
        </div>
        <div
          style={{ marginTop: "1vw", marginBottom: "3vw" }}
          className="modal-section"
        >
          <ul style={{ listStyle: "disc", marginLeft: "1.5vw" }}>
            <li>
              <div>
                <strong>Real-Time Project Experience: </strong> Gain hands-on
                experience by working on real-world projects throughout the
                duration of the course. This practical approach ensures that you
                develop the skills necessary for the job market.
              </div>
            </li>
            <li>
              <div>
                <strong>100% Placement Assurance:</strong> We believe in your
                potential, and that's why we offer 100% placement assistance.
                Our dedicated placement cell will connect you with top
                companies, opening doors to lucrative job opportunities with a
                CTC of up to Rs.12 LPA.
              </div>
            </li>
            <li>
              <div>
                <strong> Refund Policy:</strong> We value your investment in
                your future. In the rare event that you don't secure a
                placement, we offer a 100% refund on your course fees. Your
                success is our priority.
              </div>
            </li>
            <li>
              <div>
                <strong>Eligibility Criteria:</strong> We welcome graduates with
                a technical background related to computers, including BCS,
                B.Tech, or any equivalent degree. Whether you're starting from
                scratch or looking to enhance your existing skills, we have a
                course tailored to meet your needs.
              </div>
            </li>
            <li>
              <div>
                <strong>Flexible Duration:</strong> We understand that everyone
                has a different starting point and learning pace. That's why our
                courses range from 3 to 12 months, depending on your skill set
                and progress. Our expert instructors will guide you every step
                of the way, ensuring you achieve mastery in full stack
                development.
              </div>
            </li>
            <li>
              <div>
                <strong>Course Fees:</strong>
                Investing in your future has never been more affordable. Our
                course fees are only Rs.5000 per month, making it accessible for
                aspiring developers like you. We believe in offering
                high-quality education at an affordable price, so you can build
                a strong foundation for your professional growth.
              </div>
            </li>

            <li>
              <div>
                <strong> Ready to Begin Your Journey ?</strong> To join our
                esteemed Code Academy, send us your updated resume at
                support@autrob.com. We'll review your application promptly and
                get back to you with the next steps. Don't miss out on this
                golden opportunity to elevate your career prospects!
              </div>
            </li>
            <li>
              <div>
                Invest in yourself today, and tomorrow you'll be a skilled full
                stack developer, ready to conquer the tech industry. Enroll with
                Autrob Code Academy and witness your dreams become a reality!
              </div>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default ResourceUpperSection;
