import React from "react";
import "../../css/ContactPage.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useNavigate } from "react-router";
import { Metadata } from "../Metadata/Metadata";

const ContactPage = ({ width }) => {
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      color: "#000000",
    },
  });
  const navigate = useNavigate();
  return (
    <div className="main_contact_page_container">
         <Metadata
        title={"Contact us | Autrob - An Innovation & Invention Company"}
      ></Metadata>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />

        <div className="contact_page_container_child_1">
          <div>
            <img
              onClick={() => navigate(-1)}
              style={{
                margin: width > 700 ? "0vw 1vw 1vw 0vw":"0vw 1vw 2vw 0vw",
                height: width > 700 ? "3vw" : "8vw",
                width: width > 700 ? "3vw" : "8vw",
                objectFit: "contain",
                cursor: "pointer",
                filter: "invert(1)",
              }}
              src={"assests/image/93634.png"}
              alt=""
            />
          </div>
          <div className="contact-us-div">
            <h1>CONTACT US</h1>
          </div>
          <div className="get_in_touch_div" style={{ marginTop: "1.2vw" }}>
            <h3>Get in touch</h3>
            <div
              className="get_in_touch_div_second_child"
              style={{
                width: width > 700 ? "60%" : "100%",
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: width > 700 ? "0.9vw" : "2.5vw",
                lineHeight: width > 700 ? "1.5vw" : "3vw",
                textAlign: "justify",
                marginTop: width > 700 ? "-0.6vw":"1vw",
              }}
            >
              <>
                Hi there, We are available 24/7 by fax, e-mail or by phone. Drop
                us line so we can talk further about that.
              </>
            </div>
            <div className="visit_us h2" style={{ marginTop: "0.5vw" }}>
              <h2>E-Mail:</h2>
              <div
                className="get_in_touch_div_second_child"
                style={{
                  width: width > 700 ? "60%" : "100%",
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: width > 700 ? "0.9vw" : "2.5vw",
                  lineHeight: width > 700 ? "1.5vw" : "3vw",
                  textAlign: "justify",
                  marginTop: width > 700 ? "-0.6vw":"1vw",
                }}
              >
              support@autrob.com
              </div>
            </div>
            <div className="visit_us" style={{ marginTop: "0.5vw" }}>
              <h2>Visit Us:</h2>
              <div
                className="get_in_touch_div_second_child"
                style={{
                  width: width > 700 ? "60%" : "100%",
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: width > 700 ? "0.9vw" : "2.5vw",
                  lineHeight: width > 700 ? "1.5vw" : "3vw",
                  textAlign: "justify",
                  marginTop: width > 700 ? "-0.6vw":"1vw",
                }}
              >
                Regal Building, 2nd Floor, 44, Regal Building, Outer Circle,
                Connaught Place, Delhi, 110001
              </div>
            </div>
            <div className="visit_us" style={{ marginTop: "0.5vw" }}>
              <h2>Contact Us:</h2>
              <div
                className="get_in_touch_div_second_child"
                style={{
                  width: "60%",
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: width > 700 ? "0.9vw" : "2.5vw",
                  lineHeight: width > 700 ? "1.5vw" : "3vw",
                  textAlign: "justify",
                  marginTop: width > 700 ? "-0.6vw":"1vw",
                }}
              >
                +91 9650040246
              </div>
            </div>
          </div>
        </div>
        <div className="contact_page_container_child_2">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3501.998588610648!2d77.2153965!3d28.6298044!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd49c7175d4f%3A0x16da96dd1f463918!2sRegal%20Building%2C%20Hanuman%20Road%20Area%2C%20Connaught%20Place%2C%20New%20Delhi%2C%20Delhi%20110001!5e0!3m2!1sen!2sin!4v1684746959818!5m2!1sen!2sin"
            style={{ border: "0px", width: "100%", height: "100%" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            title="map"
          ></iframe>
        </div>
      </ThemeProvider>
    </div>
  );
};

export default ContactPage;
