import React from "react";
import Helmet from "react-helmet";

export const Metadata = ({ title }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta
        name="description"
        content={`AUTROB lets you live life with comfort and enable to control lights of home through your smart phone as well as manually at very reasonable cost.`}
      />

      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0"
      />

      <meta
        property="og:image"
        content="../../../public/assests/image/5000 high res icon (1).png"
      />

      <meta
        name="keywords"
        content="Autrob Technologies, Robotics, Automation, Artificial Intelligence, Innovation, Technology, Cutting-edge, Ambition, Opportunity, Performance, Competitiveness, Experiences, Critical Systems, Research and Development, Smart Solutions, Intelligent Systems, Connected World, Manufacturing, Healthcare, Logistics, Agriculture, Robotics Solutions, Automation Services, Robotics Consulting, Robotics Integration, Robotics Development, AI-driven Robotics, Future of Robotics, Industrial Automation, Advanced Technology, Sustainable Solutions, Customer-centric, Efficiency, Ingenuity, Tailor-made Solutions, Innovative Solutions, Thriving Industries, Performance Optimization, Customer Experience, Pioneering Technology, Tool44, Quantum PDF, Door Lock, Hydro Plant, Invoice Maker, Training Programs, Robotics Training, Automation Courses, AI Workshops, Technology Seminars, Cutting-edge Training, Industrial Automation Courses, Robotics Development Workshops, Advanced Technology Training, AI-driven Solutions Course, Future of Robotics Seminars, Innovative Solutions Workshops, Customer Experience Training, Sustainable Solutions Course, Manufacturing Training Programs, Healthcare Automation Courses, Logistics Robotics Workshops, Agriculture Automation Training, Customer-centric Solutions Course, Performance Optimization Workshops, Research and Development Seminars, Technology Consulting Course, Customized Training Solutions, Industry-specific Courses, Robotics Integration Training, Door Lock Technology Seminars, Hydro Plant System Course, Invoice Maker Tool Training, Skill Development Programs, Professional Development Courses"
      />

      <meta property="og:title" content={title} />
      <meta
        property="og:description"
        content={
          "AUTROB lets you live life with comfort and enable to control lights of home through your smart phone as well as manually at very reasonable cost."
        }
      />
      <meta
        property="og:keywords"
        content={
          "Autrob Technologies, Robotics, Automation, Artificial Intelligence, Innovation, Technology, Cutting-edge, Ambition, Opportunity, Performance, Competitiveness, Experiences, Critical Systems, Research and Development, Smart Solutions, Intelligent Systems, Connected World, Manufacturing, Healthcare, Logistics, Agriculture, Robotics Solutions, Automation Services, Robotics Consulting, Robotics Integration, Robotics Development, AI-driven Robotics, Future of Robotics, Industrial Automation, Advanced Technology, Sustainable Solutions, Customer-centric, Efficiency, Ingenuity, Tailor-made Solutions, Innovative Solutions, Thriving Industries, Performance Optimization, Customer Experience, Pioneering Technology, Tool44, Quantum PDF, Door Lock, Hydro Plant, Invoice Maker, Training Programs, Robotics Training, Automation Courses, AI Workshops, Technology Seminars, Cutting-edge Training, Industrial Automation Courses, Robotics Development Workshops, Advanced Technology Training, AI-driven Solutions Course, Future of Robotics Seminars, Innovative Solutions Workshops, Customer Experience Training, Sustainable Solutions Course, Manufacturing Training Programs, Healthcare Automation Courses, Logistics Robotics Workshops, Agriculture Automation Training, Customer-centric Solutions Course, Performance Optimization Workshops, Research and Development Seminars, Technology Consulting Course, Customized Training Solutions, Industry-specific Courses, Robotics Integration Training, Door Lock Technology Seminars, Hydro Plant System Course, Invoice Maker Tool Training, Skill Development Programs, Professional Development Courses"
        }
      />
    </Helmet>
  );
};
