import React from "react";
import { useNavigate } from "react-router";
import { detailsArr } from "../../constants/data";
import { Metadata } from "../Metadata/Metadata";

const ResourceUpperSection = ({ width }) => {
  const navigate = useNavigate();
  return (
    <>
       <Metadata
        title={"Quantum PDF | Autrob - An Innovation & Invention Company"}
      ></Metadata>
      <section>
        <div className="resourceuppersection">
          <div className="resource_text-section">
            <div>
              <img
                onClick={() => navigate(-1)}
                style={{
                  margin: width > 700 ? "0vw 1vw 1vw 0vw" : "0vw 1vw 2vw 0vw",
                  height: width > 700 ? "3vw" : "8vw",
                  width: width > 700 ? "3vw" : "8vw",
                  objectFit: "contain",
                  cursor: "pointer",
                }}
                src={"assests/image/93634.png"}
                alt=""
              />
            </div>
            <h1>Quantum PDF</h1>
            <p>
              Quantum PDF Software is a powerful and versatile tool designed to
              enhance your PDF document management and editing capabilities.
              With an array of advanced features, Quantum PDF Software empowers
              users to handle PDF files with ease and efficiency. Whether you
              need to create, edit , convert, merge, split, secure, or annotate
              PDF documents, this software provides the tools you need to
              accomplish these tasks seamlessly.
            </p>
          </div>
          <div className="grey_box-container">
            <img
              src={"assests/image/Quantum PDF.png"}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",

                borderRadius: "5%",
              }}
              alt=""
            />
          </div>
        </div>
      </section>
      <section hidden>
        <div className="resourcemiddlesection">
          {detailsArr.map((item) => (
            <div className="resourcemiddlesection-child">
              <div className="heading-no">{item.no}</div>
              <div className="text">{item.text}</div>
            </div>
          ))}
        </div>
      </section>

      {/* Image */}
      <section>
        <div className="middle-image">
          <img src="assests/image/pexels-pavel-danilyuk-7658350.jpg" alt="" />
        </div>
      </section>

      <section hidden>
        <div className="resourcemiddlesection">
          {detailsArr.map((item) => (
            <div className="resourcemiddlesection-child">
              <div className="heading-no">{item.no}</div>
              <div className="text">{item.text}</div>
            </div>
          ))}
        </div>
      </section>
      <section>
        <div className="modal-section">
          <h1>Key features of Quantum PDF Software include:</h1>
        </div>
        <div
          style={{ marginTop: "1vw", marginBottom: "3vw" }}
          className="modal-section"
        >
          <ul style={{ listStyle: "disc", marginLeft: "1.5vw" }}>
            <li>
              <div>
                <strong>PDF Creation: </strong> Generate high-quality PDF files
                from various document formats, such as Word, Excel, PowerPoint,
                and more.
              </div>
            </li>
            <li>
              <div>
                <strong>Editing Tools:</strong> Edit text, images, and pages
                within PDF documents, allowing you to make changes and
                modifications as needed.
              </div>
            </li>
            <li>
              <div>
                <strong>Conversion Capabilities:</strong> Convert PDF files to
                popular formats like Word, Excel, PowerPoint, HTML, and image
                files, preserving the original layout and formatting.
              </div>
            </li>
            <li>
              <div>
                <strong>Merge and Split:</strong> Combine multiple PDF files
                into a single document or split a large PDF into smaller, more
                manageable files.
              </div>
            </li>
            <li>
              <div>
                <strong>Security Measures:</strong> Apply password protection
                and encryption to safeguard sensitive information within PDF
                files. Control access permissions and restrict actions such as
                printing, copying, and editing.
              </div>
            </li>
            <li>
              <div>
                <strong>Annotation and Markup:</strong>
                Add comments, annotations, and markup to PDF documents for
                collaboration and feedback purposes.
              </div>
            </li>
            <li>
              <div>
                <strong>Optical Character Recognition (OCR):</strong> Convert
                scanned or image-based PDFs into searchable and editable text,
                enabling efficient text extraction and editing.
              </div>
            </li>
            <li>
              <div>
                <strong>Form Creation and Fillable Forms:</strong> Design and
                create interactive forms with form fields, checkboxes, and
                dropdown menus. Fill out and submit forms electronically.
              </div>
            </li>
            <li>
              <div>
                <strong>Redaction:</strong>Safely remove sensitive or
                confidential information from PDFs using redaction tools,
                ensuring privacy and compliance
              </div>
            </li>
            <li>
              <div>
                <strong>Page Management:</strong> Rearrange, rotate, delete,
                extract, and insert pages within PDF documents for optimal
                organisation and customization.
              </div>
            </li>
            <li>
              <div>
                <strong>Batch Processing:</strong> Perform bulk operations on
                multiple PDF files simultaneously, saving time and effort.
              </div>
            </li>
            <li>
              <div>
                <strong>Compression:</strong> Reduce the file size of PDF
                documents without compromising quality, making it easier to
                share and store files.
              </div>
            </li>
            <li>
              <div>
                Quantum PDF Software offers a user-friendly interface, making it
                accessible for both novice and experienced users. With its
                comprehensive range of features, it serves as an all-in-one
                solution for managing, editing, and manipulating PDF documents
                efficiently and effectively.
              </div>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default ResourceUpperSection;
