import React from "react";
import { useNavigate } from "react-router";
import { detailsArr } from "../../constants/data";
import { Metadata } from "../Metadata/Metadata";

const ResourceUpperSection = ({ width }) => {
  const navigate = useNavigate();
  return (
    <>
       <Metadata
        title={"E-Invoicing Software | Autrob - An Innovation & Invention Company"}
      ></Metadata>
      <section>
        <div className="resourceuppersection">
          <div className="resource_text-section">
            <div>
              <img
                onClick={() => navigate(-1)}
                style={{
                  margin: width > 700 ? "0vw 1vw 1vw 0vw" : "0vw 1vw 2vw 0vw",
                  height: width > 700 ? "3vw" : "8vw",
                  width: width > 700 ? "3vw" : "8vw",
                  objectFit: "contain",

                  cursor: "pointer",
                }}
                src={"assests/image/93634.png"}
                alt=""
              />
            </div>
            <h1 style={{ textAlign: "left" }}>E-Invoicing Software</h1>
            <p>
              E-invoicing software is a digital solution that enables businesses
              to create, send, receive, and manage electronic invoices. It
              eliminates the need for manual paper-based invoicing processes and
              streamlines the entire invoicing cycle electronically. The
              software typically includes features such as invoice
              customization, automated calculations, payment tracking, and
              integration with accounting systems.
            </p>
          </div>
          <div className="grey_box-container">
            <img
              src={"assests/image/Autrob E-invoice (1).png"}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",

                borderRadius: "5%",
              }}
              alt=""
            />
          </div>
        </div>
      </section>
      <section hidden>
        <div className="resourcemiddlesection">
          {detailsArr.map((item) => (
            <div className="resourcemiddlesection-child">
              <div className="heading-no">{item.no}</div>
              <div className="text">{item.text}</div>
            </div>
          ))}
        </div>
      </section>

      {/* Image */}
      <section>
        <div className="middle-image">
          <img src="assests/image/pexels-cottonbro-studio-6814338.jpg" alt="" />
        </div>
      </section>

      <section hidden>
        <div className="resourcemiddlesection">
          {detailsArr.map((item) => (
            <div className="resourcemiddlesection-child">
              <div className="heading-no">{item.no}</div>
              <div className="text">{item.text}</div>
            </div>
          ))}
        </div>
      </section>
      <section>
        <div className="modal-section">
          <h1> Benefits </h1>
        </div>
        <div
          style={{ marginTop: "1vw", marginBottom: "3vw" }}
          className="modal-section"
        >
          <ul style={{ listStyle: "disc", marginLeft: "1.5vw" }}>
            <li>
              <div>
                <strong>Time and Cost Savings: </strong> E-invoicing software
                reduces the time and effort required for invoice generation,
                printing, mailing, and manual data entry. This leads to
                significant cost savings in terms of paper, printing, postage,
                and administrative resources.
              </div>
            </li>
            <li>
              <div>
                <strong>Improved Efficiency:</strong> The software automates
                invoice creation, calculation of amounts, and application of tax
                rates, minimizing errors and improving overall efficiency. It
                also enables faster invoice delivery and payment processing.
              </div>
            </li>
            <li>
              <div>
                <strong>Faster Payments:</strong> E-invoicing accelerates
                payment cycles by delivering invoices directly to the
                recipient's email or electronic portal, reducing postal delays
                and ensuring prompt payment.
              </div>
            </li>
            <li>
              <div>
                <strong>Enhanced Accuracy and Compliance:</strong> The software
                helps maintain accurate and compliant invoices by automatically
                applying tax rules, including local tax regulations, and
                providing data validation checks.
              </div>
            </li>
            <li>
              <div>
                <strong>Real-time Tracking and Monitoring:</strong>E-invoicing
                software enables businesses to track invoice status, view
                payment details, and generate reports on outstanding invoices,
                cash flow, and revenue. This improves financial visibility and
                aids in better decision-making
              </div>
            </li>
            <li>
              <div>
                <strong>Reduced Errors and Disputes:</strong>
                Electronic invoicing minimizes manual data entry errors,
                discrepancies, and disputes, as information is directly
                transferred electronically, maintaining consistency and
                accuracy.
              </div>
            </li>
            <li>
              <div>
                <strong>Eco-Friendly and Sustainability:</strong> By eliminating
                paper-based invoicing processes, e-invoicing software
                contributes to environmental sustainability by reducing paper
                consumption, waste generation, and carbon footprint.
              </div>
            </li>
            <li>
              <div>
                <strong>Seamless Integration:</strong> Many e-invoicing software
                solutions offer integration with accounting and ERP systems,
                allowing for seamless data transfer, synchronization, and
                streamlined financial workflows.
              </div>
            </li>
            <li>
              <div>
                <strong>Enhanced Customer Experience:</strong>Electronic
                invoices are delivered promptly to customers, providing them
                with a convenient and modern invoicing experience. It can also
                enable self-service options for clients to view, download, and
                pay invoices online.
              </div>
            </li>
            <li>
              <div>
                <strong>Scalability and Adaptability:</strong> E-invoicing
                software can accommodate the growing needs of businesses,
                handling a high volume of invoices and adapting to changing
                regulatory requirements.
              </div>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default ResourceUpperSection;
