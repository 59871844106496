import React, { useEffect } from "react";
import "../../css/Home.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Link } from "react-router-dom";
import Project from "../home/Project";
import ComapnyDes from "../home/ComapnyDes";
import About from "../home/About";
import Banner from "../home/Banner";

import { navVariants } from "../../utils/motion";
import ResourcesSlider from "./ResourcesSlider";
import Blog from "./Blog";
import { Metadata } from "../Metadata/Metadata";
const Home = ({ width }) => {
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      color: "black",
    },
  });
  useEffect(() => {
    window.scrollTo(0, 0, { behavior: "smooth" });
  }, []);
  return (
    <div
      style={{
        paddingLeft: "3.5vw",
        paddingRight: "3.5vw",
        paddingTop: "1vw",
        width: "100vw",
      }}
      className="root"
    >
           <Metadata
        title={"Autrob - An Innovation & Invention Company"}
      ></Metadata>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <nav
          variants={navVariants}
          initial="hidden"
          whileInView="show"
          viewport={{ once: true }}
          //   className={`${styles.xPaddings} py-8 relative`}
        >
          <nav>
            <div className="nav_1_container">
              <div className="logo">
                <img
                  src="assests/image/5000 high res icon (1).png"
                  alt="#"
                ></img>
              </div>
              <div className="logo_content">AN INNOVATION & INVENTION CO.</div>
            </div>
            <div className="nav_2_container">
              <Link
                to={"/about"}
                style={{ textDecoration: "none", color: "#ffffff" }}
              >
                <li> About Us</li>
              </Link>

              <a
                href="#project"
                style={{ textDecoration: "none", color: "#ffffff" }}
              >
                <li> Project</li>
              </a>
              <Link
                to={"/course"}
                style={{ textDecoration: "none", color: "#ffffff" }}
              >
                <li>Course </li>
              </Link>
              <a
                href="#blog"
                style={{ textDecoration: "none", color: "#ffffff" }}
              >
                <li>Blogs</li>
              </a>

              <Link
                to={"/contactus"}
                style={{ textDecoration: "none", color: "#ffffff" }}
              >
                <li>Contact Us</li>
              </Link>
            </div>
          </nav>
        </nav>
        <section>
          <Banner width={width} />
        </section>
        <section style={{ marginTop: "-6vw" }}>
          <Project width={width} />
        </section>

        <section className="home__slider" style={{ marginTop: "4vw" }}>
          {/* <div className="first_product">
              <img src="assests/image/Thumbnail.png" alt=""></img>
            </div>
            <div className="second_product">
              <img src="assests/image/Thumbnail (1).png" alt=""></img>
            </div> */}
          <ResourcesSlider width={width} />
        </section>

        <section style={{ marginTop: "2vw" }}>
          <ComapnyDes width={width} />
        </section>

        <section style={{ marginTop: "2vw" }}>
          <h3
            style={{
              textAlign: "center",
              fontFamily: "Poppins",
              fontWeight: "500",
              fontSize: "2.6vw !important",
              letterSpacing: "1px",
            }}
            className="about_heading"
          >
            Have a project?Let's make great things!
          </h3>

          <h3
            style={{
              textAlign: "center",
              fontFamily: "Poppins",
              fontWeight: "700",
              fontSize: width > 700 ? "1vw" : "3vw",
              letterSpacing: "0.3vw",
              color: "grey",
              marginTop: width > 700 ? "0.5vw" : "1vw",
            }}
          >
            Let's work together
          </h3>
          <About width={width} />
        </section>
        <section style={{ marginTop: "3vw" }}>
          <Blog width={width} />
        </section>
        <section style={{ marginTop: "3vw" }}>
          <h3
            style={{
              textAlign: "center",
              fontFamily: "Poppins",
              fontWeight: "500",
              fontSize: width > 700 ? "2.6vw" : "7vw",
              letterSpacing: "1px",
            }}
          >
            Join Us
          </h3>
          <div className="joinus">
            <p
              style={{
                fontFamily: "Poppins",
                fontWeight: "500",
                marginTop: "1vw",
                textAlign: "justify",
                fontSize: width > 700 ? "0.91vw" : "3vw",
                letterSpacing: "1px",
                lineHeight: width > 700 ? "1.5vw" : "4.5vw",
                padding: width > 700 ? "0vw 1vw" : "0vw 0vw",
              }}
            >
              Are you passionate about working with clients to solve their
              technological issues? At Autrob Technologies, we believe in the
              power of innovation and collaboration to drive meaningful change.
              We are constantly seeking new talent to join our team and
              contribute to our mission of transforming industries through
              cutting-edge technology.
            </p>
            <p
              style={{
                fontFamily: "Poppins",
                fontWeight: "500",
                marginTop: "1vw",
                textAlign: "justify",
                fontSize: width > 700 ? "0.91vw" : "3vw",
                letterSpacing: "1px",
                lineHeight: width > 700 ? "1.5vw" : "4.5vw",
                padding: width > 700 ? "0vw 1vw" : "0vw 0vw",
              }}
            >
              If you possess a deep understanding of technology and have a knack
              for problem-solving, we would love to hear from you. We value
              individuals who are enthusiastic, creative, and committed to
              delivering exceptional results. Whether you're an experienced
              professional or just starting your career, we offer a supportive
              and dynamic work environment that fosters growth and learning.
            </p>
            <p
              style={{
                fontFamily: "Poppins",
                fontWeight: "500",
                marginTop: "1vw",
                textAlign: "justify",
                fontSize: width > 700 ? "0.91vw" : "3vw",
                letterSpacing: "1px",
                lineHeight: width > 700 ? "1.5vw" : "4.5vw",
                padding: width > 700 ? "0vw 1vw" : "0vw 0vw",
              }}
            >
              To explore the possibility of joining our team, send us your
              resume and a technological idea that excites you. We want to know
              how you envision making a difference and contributing to our
              mission. Please email your resume and idea to support@autrob.com,
              and our team will review your application.
            </p>
          </div>
        </section>
        <div className="home-footer2">
     
      © 2023, Autrob Technologies Private Limited . All Rights Reserved
    </div>
      </ThemeProvider>
    </div>
  );
};

export default Home;
