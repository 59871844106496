import React from "react";
import { useNavigate } from "react-router";
import { detailsArr } from "../../constants/data";
import { Metadata } from "../Metadata/Metadata";

const ResourceUpperSection = ({width}) => {
    const navigate = useNavigate()
  return (
    <>
       <Metadata
        title={"Tool 44 | Autrob - An Innovation & Invention Company"}
      ></Metadata>
      <section>
        <div className="resourceuppersection">
          <div className="resource_text-section">
          <div>
          <img
              onClick={() => navigate(-1)}
              style={{
                margin: width > 700 ? "0vw 1vw 1vw 0vw":"0vw 1vw 2vw 0vw",
                height: width>700? "3vw":'8vw',
                width:  width>700? "3vw":'8vw',
                objectFit: "contain",
                cursor: "pointer",
             
              }}
              src={"assests/image/93634.png"}
              alt=""
            />
            </div>
            <h1>Tool 44</h1>
            <p>
              Tool44.com is a comprehensive business platform aimed at helping
              businesses manage and grow effectively. With a wide range of
              features and services, it provides solutions for marketing,
              website building, invoice creation, social media calendar
              management, expense management, talent acquisition, and more. By
              leveraging the tools and resources offered by Tool44.com,
              businesses can streamline their operations and focus on their core
              objectives, ultimately achieving greater efficiency and growth.
            </p>
          </div>
          <div className="grey_box-container">
            <img
              src={"assests/image/Tool44.png"}
              style={{
                width: "100%",
                height:'100%',
                objectFit: "cover",
                 
                borderRadius: "5%",
              }}
              alt=""
            />
          </div>
        </div>
      </section>
      <section hidden>
        <div className="resourcemiddlesection">
          {detailsArr.map((item) => (
            <div className="resourcemiddlesection-child">
              <div className="heading-no">{item.no}</div>
              <div className="text">{item.text}</div>
            </div>
          ))}
        </div>
      </section>

      {/* Image */}
      <section>
        <div className="middle-image">
          <img src="assests/image/pexels-cadomaestro-1170412.jpg" alt="" />
        </div>
      </section>

      <section hidden>
        <div className="resourcemiddlesection">
          {detailsArr.map((item) => (
            <div className="resourcemiddlesection-child">
              <div className="heading-no">{item.no}</div>
              <div className="text">{item.text}</div>
            </div>
          ))}
        </div>
      </section>
      <section>
        <div className="modal-section">
          <h1>Benefits</h1>
        </div>
        <div
          style={{ marginTop: "1vw", marginBottom: "3vw" }}
          className="modal-section"
        >
          <ul style={{ listStyle: "disc", marginLeft: "1.5vw" }}>
            <li>
              <div>
                <strong>Marketplace</strong> : Launch or sell your
                products/services with zero marketing costs.
              </div>
            </li>
            <li>
              <div>
                <strong>Manage Expenditure:</strong> Easily manage all your
                expenses on a user-friendly platform.
              </div>
            </li>
            <li>
              <div>
                <strong>Business Virtual Assistant:</strong> Get assistance,
                updates, and growth training for your business.
              </div>
            </li>
            <li>
              <div>
                <strong>Reward & Content:</strong> Enjoy exciting rewards and
                recognition for achievements.
              </div>
            </li>
            <li>
              <div>
                <strong>Save Cost:</strong> Compare options and choose
                affordable solutions to save costs.
              </div>
            </li>
            <li>
              <div>
                <strong>Order and Booking Calendar:</strong>
                Effortlessly manage services with an intuitive order and booking
                calendar.
              </div>
            </li>
            <li>
              <div>
                <strong>Website Builder:</strong> Create a professional website
                in minutes, no coding or design experience required.
              </div>
            </li>
            <li>
              <div>
                <strong>Connect with Experts:</strong> Access a diverse pool of
                talented professionals for consulting, design, marketing, and
                more.
              </div>
            </li>
            <li>
              <div>
                <strong>Simplified Tax Reporting:</strong> Access taxation
                reports for easy tax compliance.
              </div>
            </li>
            <li>
              <div>
                <strong>Connect with Talents:</strong> Hire freelancers or
                employees for your business needs.
              </div>
            </li>
            <li>
              <div>
                <strong>Blogging for Marketing:</strong> Write and publish
                marketing-focused blog posts to enhance online presence and
                reach a wider audience.
              </div>
            </li>
            <li>
              <div>
                <strong>Social Media Automation:</strong> Automate social media
                posts for seamless scheduling and content sharing.
              </div>
            </li>
            <li>
              <div>
                <strong>Professional Letterhead Creation:</strong>
                Automatically create branded letterheads for professional and
                consistent communication.
              </div>
            </li>
            <li>
              <div>
                <strong>Inspiration from Business Stories:</strong>
                Explore resources and community for business ideas, fostering
                innovation and growth opportunities.
              </div>
            </li>
            <li>
              <div>
                <strong>Access to Business Plans:</strong> Draw inspiration from
                successful plans and strategies shared on Tool44.com for
                developing your own.
              </div>
            </li>
            <li>
              <div>
                <strong>Problem Solving and Expertise:</strong> Find solutions
                to business problems through case studies and tap into community
                expertise.
              </div>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default ResourceUpperSection;
