import React from "react";
import Grid from "@mui/material/Grid";

import { clintArrImage } from "../../constants/data";
import Slider from "react-slick";
const ClientGrid = ({width}) => {
  var settings = {
    infinite: true,
    centerMode: false,
    speed: 2000,
    slidesToShow: 6,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 3500,
    
    arrows: false,
    focusOnSelect: false,
    swipeToSlide: false,

  };
  var settings1 = {
    infinite: true,
    centerMode: false,
    speed: 2000,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 3500,
    arrows: false,
    focusOnSelect: false,
    swipeToSlide: false,

  };
  return (
    <>
      <Slider {...(width > 700 ? settings : settings1)}>
        {clintArrImage.map((data_img, idx) => {
          return (
            <Grid
              item
              xs={6}
              sm={2.5}
              md={2.5}
              style={{
                width: "10vw",
                height: "10vw",
                maxWidth: "12vw",
                borderRadius: "24px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              className="clint_img"
            >
              <img
                src={data_img.image}
                style={{ objectFit: "contain" }}
                alt=""
              ></img>
            </Grid>
          );
        })}
      </Slider>
    </>
  );
};

export default ClientGrid;
