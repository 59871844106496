import React from "react";
import { useNavigate } from "react-router";
import { detailsArr } from "../../constants/data";
import { Metadata } from "../Metadata/Metadata";

const ResourceUpperSection = ({ width }) => {
  const navigate = useNavigate();
  return (
    <>
       <Metadata
        title={"Smart Door Lock | Autrob - An Innovation & Invention Company"}
      ></Metadata>
      <section>
        <div className="resourceuppersection">
          <div className="resource_text-section">
            <div>
              <img
                onClick={() => navigate(-1)}
                style={{
                  margin: width > 700 ? "0vw 1vw 1vw 0vw" : "0vw 1vw 2vw 0vw",
                  height: width > 700 ? "3vw" : "8vw",
                  width: width > 700 ? "3vw" : "8vw",
                  objectFit: "contain",
                  cursor: "pointer",
                }}
                src={"assests/image/93634.png"}
                alt=""
              />
            </div>
            <h1>Smart Door Lock</h1>
            <p>
              A smart door lock is an advanced security solution that replaces
              traditional key-based locks with a digital system. It uses
              technology such as Bluetooth, Wi-Fi, or biometric recognition to
              provide secure access to a property. Smart door locks offer
              convenience, enhanced security, and remote access control
              features.
            </p>
          </div>
          <div className="grey_box-container">
            <img
              src={"assests/image/Autrob Smart Lock (1).png"}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "5%",
              }}
              alt=""
            />
          </div>
        </div>
      </section>
      <section hidden>
        <div className="resourcemiddlesection">
          {detailsArr.map((item) => (
            <div className="resourcemiddlesection-child">
              <div className="heading-no">{item.no}</div>
              <div className="text">{item.text}</div>
            </div>
          ))}
        </div>
      </section>

      <section>
        <div className="middle-image">
          <img src="assests/image/pexels-pixabay-279810.jpg" alt="" />
        </div>
      </section>

      <section hidden>
        <div className="resourcemiddlesection">
          {detailsArr.map((item) => (
            <div className="resourcemiddlesection-child">
              <div className="heading-no">{item.no}</div>
              <div className="text">{item.text}</div>
            </div>
          ))}
        </div>
      </section>
      <section>
        <div className="modal-section">
          <h1>Benefits</h1>
        </div>
        <div
          style={{ marginTop: "1vw", marginBottom: "3vw" }}
          className="modal-section"
        >
          <ul style={{ listStyle: "disc", marginLeft: "1.5vw" }}>
            <li>
              <div>
                <strong>Keyless Entry: </strong> Smart door locks eliminate the
                need for physical keys. Users can unlock the door using various
                methods, including smartphone apps, key fobs, PIN codes, or
                biometric authentication (fingerprint, facial recognition,
                etc.). This eliminates the risk of lost or stolen keys.
              </div>
            </li>
            <li>
              <div>
                <strong>Enhanced Security:</strong> Smart door locks employ
                advanced encryption techniques, making them more secure than
                traditional locks. They often have built-in tamper alarms and
                auto-locking features to prevent unauthorized access.
              </div>
            </li>
            <li>
              <div>
                <strong>Remote Access and Control:</strong> With smart door
                locks, users can remotely control and monitor access to their
                property. This allows them to lock or unlock the door from
                anywhere using a smartphone app, providing convenience and added
                security.
              </div>
            </li>
            <li>
              <div>
                <strong>Temporary Access and Guest Management:</strong> Smart
                door locks enable temporary access codes or digital keys to be
                assigned to guests, service providers, or renters. Users can
                easily manage and revoke access privileges, ensuring controlled
                and secure entry.
              </div>
            </li>
            <li>
              <div>
                <strong>Activity Logs and Notifications:</strong> Many smart
                door locks maintain a log of door activities, including
                lock/unlock events and attempted break-ins. Users receive
                notifications in real-time, keeping them informed about who
                enters or leaves the property.
              </div>
            </li>
            <li>
              <div>
                <strong>Integration with Smart Home Systems:</strong>
                Smart door locks can integrate with existing smart home
                ecosystems, allowing users to incorporate them into routines,
                voice assistants, or automation scenarios. For example, the lock
                can automatically activate the security system when locked.
              </div>
            </li>
            <li>
              <div>
                <strong>Convenience and Flexibility:</strong> Users no longer
                need to fumble with keys or worry about forgetting to lock the
                door. Smart door locks provide a convenient and flexible way to
                enter and secure the property, especially when hands are full or
                during inclement weather.
              </div>
            </li>
            <li>
              <div>
                <strong>Easy Installation and Retrofitting:</strong> Most smart
                door locks are designed for easy installation, often replacing
                existing deadbolt locks without the need for extensive
                modifications. This makes them accessible to homeowners and
                renters alike.
              </div>
            </li>
            <li>
              <div>
                <strong>Energy Efficiency:</strong>Some smart door locks have
                energy-saving features such as low-power modes, battery status
                monitoring, and the ability to integrate with home automation
                systems to optimize energy usage.
              </div>
            </li>
            <li>
              <div>
                <strong>Stylish and Modern Design:</strong> Smart door locks
                come in various designs, finishes, and styles, allowing users to
                choose a lock that matches their aesthetic preferences and
                enhances the overall appearance of their door.
              </div>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default ResourceUpperSection;
