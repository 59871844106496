import React from "react";
import { useNavigate } from "react-router";
import { detailsArr } from "../../constants/data";
import { Metadata } from "../Metadata/Metadata";

const ResourceUpperSection = ({ width }) => {
  const navigate = useNavigate();
  return (
    <>
       <Metadata
        title={"Water Level Controller | Autrob - An Innovation & Invention Company"}
      ></Metadata>
      <section>
        <div className="resourceuppersection">
          <div className="resource_text-section">
            <div>
              <img
                onClick={() => navigate(-1)}
                style={{
                  margin: width > 700 ? "0vw 1vw 1vw 0vw" : "0vw 1vw 2vw 0vw",
                  height: width > 700 ? "3vw" : "8vw",
                  width: width > 700 ? "3vw" : "8vw",
                  objectFit: "contain",
                  cursor: "pointer",
                }}
                src={"assests/image/93634.png"}
                alt=""
              />
            </div>
            <h1>Water Level Controller</h1>
            <p>
            The water level controller unit is a reliable device designed to automatically control the water level in tanks. It consists of sensors placed at different levels in the tank, a motor switch, and LED indicators. When the water level drops, the motor starts automatically, refilling the tank. Once the water reaches the desired level, the motor shuts off. The unit comes with a 1-year manufacturer's warranty and includes all necessary components for installation.
            </p>
          </div>
          <div className="grey_box-container">
            <img
              src={"assests/image/Water Level Controller (1).png"}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",

                borderRadius: "5%",
              }}
              alt=""
            />
          </div>
        </div>
      </section>
      <section hidden>
        <div className="resourcemiddlesection">
          {detailsArr.map((item) => (
            <div className="resourcemiddlesection-child">
              <div className="heading-no">{item.no}</div>
              <div className="text">{item.text}</div>
            </div>
          ))}
        </div>
      </section>

      {/* Image */}
      <section>
        <div className="middle-image">
          <img src="assests/image/pexels-brett-sayles-7178310.jpg" alt="" />
        </div>
      </section>

      <section hidden>
        <div className="resourcemiddlesection">
          {detailsArr.map((item) => (
            <div className="resourcemiddlesection-child">
              <div className="heading-no">{item.no}</div>
              <div className="text">{item.text}</div>
            </div>
          ))}
        </div>
      </section>
      <section>
        <div className="modal-section">
          <h1>Benefits</h1>
        </div>
        <div
          style={{ marginTop: "1vw", marginBottom: "3vw" }}
          className="modal-section"
        >
          <ul style={{ listStyle: "disc", marginLeft: "1.5vw" }}>
            <li>
              <div>
                <strong>Convenience: </strong> The water level controller
                eliminates the need for manual monitoring and operation,
                providing a hassle-free solution for maintaining water levels in
                tanks.
              </div>
            </li>
            <li>
              <div>
                <strong>Energy-saving:</strong> The automatic motor control
                ensures that the motor operates only when necessary, reducing
                energy consumption.
              </div>
            </li>
            <li>
              <div>
                <strong>Long-lasting:</strong> The carbon sensors used in the
                unit are resistant to oxidation, ensuring a longer lifespan and
                reliable performance.
              </div>
            </li>
            <li>
              <div>
                <strong>Versatile compatibility:</strong> The controller is
                suitable for use with single-phase MCB starter panels,
                submersible motors, and various types of tanks including
                underground, overhead, pressure pumps, tube wells, and deep
                borings.
              </div>
            </li>
            <li>
              <div>
                <strong>Easy installation:</strong> The package includes all
                necessary components, such as sensors, mounting screws, and a
                user manual, making it convenient for installation.
              </div>
            </li>
            <li>
              <div>
                <strong>LED Indicators:</strong>
                The product features LED indicators for power status and motor
                start/stop, providing visual feedback on the device's operation.
              </div>
            </li>
            <li>
              <div>
                <strong>Warranty and Service:</strong> The water level
                controller unit comes with a 1-year manufacturer's warranty,
                ensuring peace of mind and replacement service if required.
              </div>
            </li>
          </ul>
        </div>
   
      </section>
    </>
  );
};

export default ResourceUpperSection;
