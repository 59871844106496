import React, { useEffect } from "react";
import ResourceUpperSection from "./ResourceUpperSection";

import "../../css/resourcepage.css";

const ResourcesPage6 = ({width}) => {
    useEffect(() => {
        window.scrollTo(0, 0, { behavior: "smooth" });
      }, []);
  return (
    <div className="root">
      <ResourceUpperSection  width={width}/>
    </div>
  );
};

export default ResourcesPage6;
