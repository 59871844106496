import React from "react";
import ClientGrid from "./ClientGrid";

const ComapnyDes = ({width}) => {
  return (
    <>
      <h3
        style={{
          textAlign: "center",
          fontFamily: "Poppins",
          fontWeight: "500",
          fontSize:width>700? "2.6vw":"7vw",
          letterSpacing: "-1px",
          marginTop: width>700? "1vw":"2vw",
          marginBottom: "1.5vw",
        }}
      >
        What We Do ?
      </h3>
      <div className="service_description_container">
        <div className="service_des">
          <>
            We offer general solutions to the technological problems faced by
            the people and try to make their life a bit less complicated. We do
            our job in the best way possible so that you don’t have to worry
            about those things ever again
          </>
        </div>
        <div className="service_list">
          <ul style={{paddingLeft:"1vw"}}>
            <li
              style={{
                fontFamily: "Poppins",
                lineHeight: width>700? "2.2vw":"5vw",
                fontSize: width>700? "1.25vw":"3.5vw",
              }}
            >
              <span style={{ fontSize: width>700? "1.25vw":"3.5vw", fontWeight: "bolder" }}>
                Idea & Concept -
              </span>
              &nbsp; Try to identify and validate the best, advanced, and futuristic
              idea.
            </li>
            <li
              style={{
                fontFamily: "Poppins",
                lineHeight: width>700? "2.2vw":"5vw",
                fontSize: width>700? "1.25vw":"3.5vw",
              }}
            >
              <span style={{ fontSize:  width>700? "1.25vw":"3.5vw", fontWeight: "bolder" }}>
                Research - 
              </span>
                 &nbsp;We research technologies with intelligent and data-driven
              automation systems.
            </li>
            <li
              style={{
                fontFamily: "Poppins",
                lineHeight: width>700? "2.2vw":"5vw",
                fontSize: width>700? "1.25vw":"3.5vw",
              }}
            >
              <span style={{ fontSize:  width>700? "1.25vw":"3.5vw", fontWeight: "bolder" }}>
                Design & Development -
              </span>
              &nbsp;Evaluate the new technologies to enhance precision and operate
              well enough.
            </li>
            <li
              style={{
                fontFamily: "Poppins",
                lineHeight: width>700? "2.2vw":"5vw",
                fontSize: width>700? "1.25vw":"3.5vw",
              }}
            >
              <span style={{ fontSize: width>700? "1.25vw":"3.5vw", fontWeight: "bolder" }}>
                Scale -{" "}
              </span>
              &nbsp; Making Product more usable
            </li>
          </ul>
        </div>

        <div className="client_description" style={{ marginTop: "3rem" }}>
          <h3
            style={{
              textAlign: "center",
              fontFamily: "Poppins",
              fontWeight: "500",
              fontSize: width>700?"2.6vw":"7vw",
              letterSpacing: "-1px",
              marginBottom: "0.7em",
            }}
          >
            Clients
          </h3>
          <div className="client_des_grid">
            <ClientGrid  width={width}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComapnyDes;
