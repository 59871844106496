import React from "react";

import Slider from "react-slick";

const Blog = ({ width }) => {
  let dragging = false;

  let settings = {
    infinite: true,
    centerMode: false,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 2000,
    swipeToSlide: false,
    arrows: false,
    focusOnSelect: false,
    cssEase: "linear",
    beforeChange: () => (dragging = true),
    afterChange: () => (dragging = false),
  };
  var settings1 = {
    infinite: true,
    centerMode: false,
    adaptiveHeight: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    arrows: false,
  };
  return (
    <>
      <div id="blog" className="project_heading">
        Our Blogs
      </div>
      <div style={{ marginTop: "2vw" }}>
        <Slider {...(width > 700 ? settings : settings1)}>
          <div>
            <a
              onClick={(e) => dragging && e.preventDefault()}
              href="/How_Technology_Eliminates_Human_Errors_in_Every_Industry"
              style={{ textDecoration: "none" }}
              rel="noreferrer"
            >
              <div
                style={{
                  background: `url('assests/image/pexels-alex-knight-2599244.jpg') center center / cover no-repeat`,
                }}
                className="first_productx"
              >
                <div>
                How Technology Eliminates Human Errors in Every Industry
                </div>
              </div>
            </a>
          </div>
        
          <div>
            <a
              onClick={(e) => dragging && e.preventDefault()}
              href="/Hydroponic_Automation:_Revolutionizing_Agriculture_for_a _Stainable_Future"
              style={{ textDecoration: "none" }}
              rel="noreferrer"
            >
              <div
                style={{
                  background: `url('assests/image/pexels-pragyan-bezbaruah-4199758.jpg') center center / cover no-repeat`,
                }}
                className="first_productx"
              >
                <div>
                  Hydroponic Automation: Revolutionizing Agriculture for a
                  Sustainable Future
                </div>
              </div>
            </a>
          </div>
       
          <div>
            <a
              onClick={(e) => dragging && e.preventDefault()}
              href="/The_Promising_Upcoming_Technology_Set_to_Revolutionize_the_World"
              style={{ textDecoration: "none" }}
              rel="noreferrer"
            >
              <div
                style={{
                  background: `url('assests/image/pexels-cottonbro-studio-8721318.jpg') center center / cover no-repeat`,
                }}
                className="first_productx"
              >
                <div>
                The Promising Upcoming Technology Set to Revolutionize the World
                </div>
              </div>
            </a>
          </div>
        
          <div>
            <a
              onClick={(e) => dragging && e.preventDefault()}
              href="/The_Evolution_of_Paperwork_Documentation"
              style={{ textDecoration: "none" }}
              rel="noreferrer"
            >
              <div
                style={{
                  background: `url('assests/image/pexels-pixabay-357514.jpg') center center / cover no-repeat`,
                }}
                className="first_productx"
              >
                <div>
                The Evolution of Paperwork Documentation
                </div>
              </div>
            </a>
          </div>
       
        </Slider>
      </div>{" "}
    </>
  );
};

export default Blog;
