import React from "react";
import { useNavigate } from "react-router";
import { detailsArr } from "../../constants/data";
import "../../css/resourcepage.css";
import { Metadata } from "../Metadata/Metadata";
const Blog2 = ({ width }) => {
  const navigate = useNavigate();
  return (
    <>
      <Metadata
        title={
          "Hydroponic Automation: Revolutionizing Agriculture for a Sustainable Future | Autrob - An Innovation & Invention Company"
        }
      ></Metadata>
      <section>
        <div className="resourceuppersection">
          <div
            style={{ width: "100%", flexBasis: "100%" }}
            className="resource_text-section"
          >
            <div>
              <img
                onClick={() => navigate(-1)}
                style={{
                  margin: width > 700 ? "0vw 1vw 1vw 0vw" : "0vw 1vw 2vw 0vw",
                  height: width > 700 ? "3vw" : "8vw",
                  width: width > 700 ? "3vw" : "8vw",
                  objectFit: "contain",
                  cursor: "pointer",
                }}
                src={"assests/image/93634.png"}
                alt=""
              />
            </div>
            <h1
              style={{
                textAlign: "left",
                fontSize: width > 700 ? "2.9vw" : "",
              }}
            >
              Hydroponic Automation: Revolutionizing Agriculture for a
              Sustainable Future
            </h1>
            <p>
              In the quest for sustainable and efficient agriculture,
              hydroponics has emerged as a game-changer. This innovative
              cultivation method allows plants to grow without soil, using a
              nutrient-rich water solution instead. To further enhance
              productivity and precision, hydroponic systems are increasingly
              being automated. In this blog, we will explore the world of
              hydroponic automation and its transformative impact on modern
              agriculture.
            </p>
          </div>
        </div>
      </section>
      <section hidden>
        <div className="resourcemiddlesection">
          {detailsArr.map((item) => (
            <div className="resourcemiddlesection-child">
              <div className="heading-no">{item.no}</div>
              <div className="text">{item.text}</div>
            </div>
          ))}
        </div>
      </section>

      {/* Image */}
      <section>
        <div style={{ display: "block" }} className="middle-image">
          <img
            src="assests/image/pexels-pragyan-bezbaruah-4199758.jpg"
            alt=""
          />
        </div>
      </section>

      <section hidden>
        <div className="resourcemiddlesection">
          {detailsArr.map((item) => (
            <div className="resourcemiddlesection-child">
              <div className="heading-no">{item.no}</div>
              <div className="text">{item.text}</div>
            </div>
          ))}
        </div>
      </section>
      <section>
        <div
          className="blogtext"
          dangerouslySetInnerHTML={{
            __html: `<h3><strong></strong>Precision Nutrient Delivery:</h3>
<p dir="ltr">Hydroponic automation ensures precise nutrient delivery to plants. Automated systems monitor and adjust nutrient levels in the water solution, maintaining optimal conditions for plant growth. By eliminating the guesswork associated with traditional soil-based farming, hydroponic automation maximizes nutrient absorption, resulting in faster growth, healthier plants, and higher yields.</p>
<p><strong>&nbsp;</strong></p>
<h3 dir="ltr" role="presentation">Automated Irrigation:</h3>
<p dir="ltr">Hydroponic automation eliminates the need for manual irrigation. Sensors and timers are utilized to deliver water in precise quantities and intervals, ensuring that plants receive adequate hydration without the risk of overwatering or underwatering. This level of precision promotes water conservation and prevents waterborne diseases often associated with traditional farming methods.</p>
<p><strong>&nbsp;</strong></p>
<h3 dir="ltr" role="presentation">Climate Control:</h3>
<p dir="ltr">Hydroponic automation enables precise control over environmental factors such as temperature, humidity, and light. Automated systems utilize sensors, timers, and climate control equipment to maintain optimal growing conditions throughout the day and night. This level of control allows for year-round cultivation, irrespective of external weather conditions, and ensures consistent plant growth and quality.</p>
<p><strong>&nbsp;</strong></p>
<h3 dir="ltr" role="presentation">pH and EC Monitoring:</h3>
<p dir="ltr">Maintaining the correct pH and electrical conductivity (EC) levels in the nutrient solution is critical for hydroponic success. Automation systems continuously monitor pH and EC levels, making real-time adjustments as needed. This eliminates the labor-intensive task of manual monitoring and adjustment, ensuring that plants receive an optimized nutrient solution for their growth and development.</p>
<p><strong>&nbsp;</strong></p>
<h3 dir="ltr" role="presentation">Remote Monitoring and Control:</h3>
<p dir="ltr">Hydroponic automation systems often come equipped with remote monitoring and control capabilities. Farmers can access real-time data, adjust settings, and receive alerts about any fluctuations or issues in the system, all from a mobile device or computer. This feature empowers farmers to manage their hydroponic operations efficiently, even from a distance, saving time and resources.</p>
<p><strong>&nbsp;</strong></p>
<h3 dir="ltr" role="presentation">Data-driven Decision Making:</h3>
<p dir="ltr">Automated hydroponic systems generate a wealth of data regarding environmental conditions, nutrient levels, plant growth, and more. This data can be analyzed to gain valuable insights into crop performance, identify patterns, and optimize cultivation practices. By leveraging data-driven decision making, farmers can fine-tune their hydroponic setups, enhance productivity, and achieve better overall crop quality.</p>
<p><strong>&nbsp;</strong></p>
<h3 dir="ltr">Conclusion:</h3>
<p dir="ltr">Hydroponic automation is revolutionizing the way we cultivate crops, offering a sustainable and efficient alternative to traditional farming methods. By harnessing the power of automation, precision nutrient delivery, climate control, and remote monitoring, hydroponic systems maximize crop yields, conserve resources, and minimize the impact on the environment. As the world faces increasing challenges in food production and resource scarcity, hydroponic automation represents a promising solution for sustainable agriculture. By embracing this technology, we can build a future where food is grown efficiently, locally, and with minimal environmental impact.</p>
`,
          }}
        ></div>
      </section>
    </>
  );
};

export default Blog2;
