import { BrowserRouter, Route, Routes } from "react-router-dom";
import './css/carousel.css'
import Home from "./component/home/Home";
import ContactPage from "./component/contact/ContactPage.jsx";
import ResourcesPage from "./component/ResourcePage/ResourcesPage";
import ResourcesPage2 from "./component/Resourse2/ResourcesPage.jsx";
import { useEffect, useState } from "react";
import ResourcesPage3 from "./component/Resourse3/ResourcesPage.jsx";
import ResourcesPage4 from "./component/Resourse4/ResourcesPage.jsx";
import ResourcesPage5 from "./component/Resourse5/ResourcesPage.jsx";
import ResourcesPage6 from "./component/Resourse6/ResourcesPage.jsx";
import Blog1 from "./component/Blog/Blog.jsx";
import Blog2 from "./component/Blog/Blog2.jsx";
import Blog4 from "./component/Blog/Blog4.jsx";
import Blog3 from "./component/Blog/Blog3.jsx";
import About1 from "./component/About/About";
import Course from "./component/Course/Course";


function App() {
  const [width, setWidth] = useState(window.innerWidth);

  //FUNCTIONS
  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home width={width} />}></Route>
          <Route
            path="/about"
            element={<About1 width={width}></About1>}
          ></Route>
          <Route
            path="/contactus"
            element={<ContactPage width={width}></ContactPage>}
          ></Route>
          <Route
            path="/tool44"
            element={<ResourcesPage width={width} />}
          ></Route>
          <Route
            path="/quantum_pdf"
            element={<ResourcesPage2 width={width} />}
          ></Route>
          <Route
            path="/e_invoicing"
            element={<ResourcesPage3 width={width} />}
          ></Route>
          <Route
            path="/water_level_controller"
            element={<ResourcesPage4 width={width} />}
          ></Route>
          <Route
            path="/smart_door_lock"
            element={<ResourcesPage5 width={width} />}
          ></Route>
          <Route
            path="/hydroponic_automation"
            element={<ResourcesPage6 width={width} />}
          ></Route>
          <Route
            path="/course"
            element={<Course width={width} />}
          ></Route>
          <Route
            path="/How_Technology_Eliminates_Human_Errors_in_Every_Industry"
            element={<Blog1 width={width} />}
          ></Route>
          <Route
            path="/Hydroponic_Automation:_Revolutionizing_Agriculture_for_a _Stainable_Future"
            element={<Blog2 width={width} />}
          ></Route>
          <Route
            path="/The_Promising_Upcoming_Technology_Set_to_Revolutionize_the_World"
            element={<Blog3 width={width} />}
          ></Route>
          <Route
            path="/The_Evolution_of_Paperwork_Documentation"
            element={<Blog4 width={width} />}
          ></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
