import React from "react";
import { useNavigate } from "react-router";
import { detailsArr } from "../../constants/data";
import "../../css/resourcepage.css";
import { Metadata } from "../Metadata/Metadata";
const Blog3 = ({ width }) => {
  const navigate = useNavigate();
  return (
    <><Metadata
    title={
      "The Promising Upcoming Technology Set to Revolutionize the World | Autrob - An Innovation & Invention Company"
    }
  ></Metadata>
      <section>
        <div className="resourceuppersection">
          <div
            style={{ width: "100%", flexBasis: "100%" }}
            className="resource_text-section"
          >
            <div>
              <img
                onClick={() => navigate(-1)}
                style={{
                  margin: width > 700 ? "0vw 1vw 1vw 0vw" : "0vw 1vw 2vw 0vw",
                  height: width > 700 ? "3vw" : "8vw",
                  width: width > 700 ? "3vw" : "8vw",
                  objectFit: "contain",
                  cursor: "pointer",
                }}
                src={"assests/image/93634.png"}
                alt=""
              />
            </div>
            <h1 style={{
                textAlign: "left",
                fontSize: width > 700 ? "2.9vw" : "",
              }}>
              The Promising Upcoming Technology Set to Revolutionize the World
            </h1>
            <p>
              As we venture further into the digital age, we are witnessing an
              exponential growth in technological advancements that have the
              potential to reshape our world. From artificial intelligence (AI)
              to quantum computing, innovative technologies are propelling us
              toward a future that was once the stuff of science fiction. In
              this blog, we will explore some of the upcoming technologies that
              are poised to change the world as we know it, along with examples
              of their transformative potential.
            </p>
          </div>
        </div>
      </section>
      <section hidden>
        <div className="resourcemiddlesection">
          {detailsArr.map((item) => (
            <div className="resourcemiddlesection-child">
              <div className="heading-no">{item.no}</div>
              <div className="text">{item.text}</div>
            </div>
          ))}
        </div>
      </section>

      {/* Image */}
      <section>
        <div style={{ display: "block" }} className="middle-image">
          <img src="assests/image/pexels-cottonbro-studio-8721318.jpg" alt="" />
        </div>
      </section>

      <section hidden>
        <div className="resourcemiddlesection">
          {detailsArr.map((item) => (
            <div className="resourcemiddlesection-child">
              <div className="heading-no">{item.no}</div>
              <div className="text">{item.text}</div>
            </div>
          ))}
        </div>
      </section>
      <section>
        <div
          className="blogtext"
          dangerouslySetInnerHTML={{
            __html: `<h3>1. Artificial Intelligence (AI):</h3>
            <p><br>AI has already made significant strides in various domains, but its true impact is yet to be fully realized. From self-driving cars to personalized medicine, AI has the potential to enhance efficiency, improve decision-making, and revolutionize industries. For instance, in healthcare, AI can analyze vast amounts of patient data to identify patterns and predict diseases, leading to earlier diagnoses and more effective treatments.</p>
            <p>&nbsp;</p>
            <h3>2. Blockchain Technology:</h3>
            <p><br>Blockchain, the technology underlying cryptocurrencies like Bitcoin, has the potential to disrupt numerous industries beyond finance. Its decentralized and immutable nature makes it highly secure and transparent. Blockchain can revolutionize supply chain management by ensuring traceability, reducing fraud, and improving efficiency. Additionally, it has the potential to transform voting systems, intellectual property protection, and digital identity verification.</p>
            <p>&nbsp;</p>
            <h3>3. Internet of Things (IoT):</h3>
            <p><br>IoT refers to the network of interconnected physical devices that can collect and exchange data. As the number of connected devices continues to grow, the impact of IoT on our daily lives will be profound. Smart homes, for example, will enable us to control various aspects of our living environments remotely, leading to energy savings and enhanced convenience. In the realm of healthcare, IoT devices can monitor patients' health conditions in real-time, allowing for timely interventions and improved patient outcomes.</p>
            <p>&nbsp;</p>
            <h3>4. Quantum Computing:</h3>
            <p><br>Quantum computing leverages the principles of quantum mechanics to perform complex computations at an unprecedented speed. With the ability to process vast amounts of data, quantum computers can solve problems that are currently intractable for classical computers. This technology has the potential to revolutionize fields such as drug discovery, optimization problems, and cryptography. For instance, it could enable the discovery of new drugs by simulating molecular interactions and designing more effective medications.</p>
            <p>&nbsp;</p>
            <h3>5. Renewable Energy Technologies:</h3>
            <p><br>As the world grapples with climate change, renewable energy technologies are gaining momentum. Solar panels, wind turbines, and advanced energy storage systems are becoming more efficient and cost-effective. These technologies can reduce our dependence on fossil fuels, mitigate greenhouse gas emissions, and promote sustainable development. The widespread adoption of renewable energy sources can transform our energy infrastructure and create a greener and more sustainable future.</p>
            <p>&nbsp;</p>
            <h3>Conclusion:</h3>
            <p><br>The technologies mentioned above represent just a fraction of the groundbreaking advancements that are on the horizon. As these technologies continue to evolve and mature, they have the potential to transform industries, improve lives, and address some of humanity's most pressing challenges. However, it is essential to recognize that alongside their immense potential, these technologies also present ethical and societal considerations that need to be carefully addressed. By embracing these upcoming technologies responsibly, we can harness their power to create a brighter and more prosperous future for all.</p>`,
          }}
        ></div>
      </section>
    </>
  );
};

export default Blog3;
