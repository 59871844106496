 export const valueArr=[
    {
        title:"Trust",
        title_des:"We build trust thr- ough responsible actions and honest relationships. "
    },
    {
        title:"Accountability",
        title_des:"Each of us is responsible for our words, our actions, and our results. "
    },
    {
        title:"Teamwork",
        title_des:"Teamwork is vital for success. We feel, act and behave as one big team. "
    },
    {
        title:"Quality",
        title_des:"Ensures customer satis-faction, profitability and the future of our employ-ees."
    },
    {
        title:"Professialism",
        title_des:"Our role is to set a high industry standard, based on data and a profess-ional improvement "
    },
    {
        title:"Customer Experience",
        title_des:"Ensuring our customers receive the products and services in a timely and efficient manner. "
    }
]

export const clintArrImage=[
    {
        image:"assests/Client/1.png"
    },
    {
        image:"assests/Client/2.png"
    },
    {
        image:"assests/Client/3.png"
    },
    {
        image:"assests/Client/4.png"
    },
    {
        image:"assests/Client/5.png"
    },
    {
        image:"assests/Client/6.png"
    },
    {
        image:"assests/Client/7.png"
    },
    {
        image:"assests/Client/8.png"
    },
    {
        image:"assests/Client/9.png"
    },
    {
        image:"assests/Client/10.png"
    },
    {
        image:"assests/Client/11.png"
    },
    {
        image:"assests/Client/12.png"
    },
    {
        image:"assests/Client/13.png"
    },
    {
        image:"assests/Client/14.png"
    },
    {
        image:"assests/Client/15.png"
    },
    {
        image:"assests/Client/16.png"
    },
    {
        image:"assests/Client/17.png"
    },
    {
        image:"assests/Client/18.png"
    },
]

export const detailsArr=[
    {
        "no" : "1",
        "text" : "Modals should only be used when an almost-instant response is required from the user. The task within the modal should also be short and non-recurring."
    },
    {
        "no" : "2",
        "text" : "Use modals to present critical urgent information to the user, e.g. system errors or something that has happened as a result of their action."
        
    },
    {
        "no" : "3",
        "text" : "Confirming user decisions can be done with a modal. It’s important to ensure that the decision, and any potential actions that may result from it, is clearly explained within the modal."
    }
]