import React from "react";

import Slider from "react-slick";

const ResourcesSlider = ({ width }) => {
  let dragging = false;

  let settings = {
    infinite: true,
    centerMode: false,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,

    swipeToSlide: false,
    arrows: false,
    focusOnSelect: false,
    cssEase: "linear",
    beforeChange: () => (dragging = true),
    afterChange: () => (dragging = false),
  };
  var settings1 = {
    infinite: true,
    centerMode: false,
    speed: 2000,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    arrows: false,
  };
  return (
    <>
      <Slider {...(width > 700 ? settings : settings1)}>
        <div>
          <a
            onClick={(e) => dragging && e.preventDefault()}
            href="/tool44"
            style={{ textDecoration: "none" }}
            rel="noreferrer"
          >
            <div className="first_product">
              <img src="assests/image/Tool44.png" alt=""></img>
            </div>
          </a>
        </div>
        <div>
          <a
            onClick={(e) => dragging && e.preventDefault()}
            href="/quantum_pdf"
            style={{ textDecoration: "none" }}
          >
            {" "}
            <div className="first_product">
              <img src="assests/image/Quantum PDF.png" alt=""></img>
            </div>
          </a>
        </div>
        <div>
          <a
            onClick={(e) => dragging && e.preventDefault()}
            href="/e_invoicing"
            style={{ textDecoration: "none" }}
            rel="noreferrer"
          >
            <div className="first_product">
              <img src="assests/image/Autrob E-invoice (1).png" alt=""></img>
            </div>
          </a>
        </div>
        <div>
          <a
            onClick={(e) => dragging && e.preventDefault()}
            href="/water_level_controller"
            style={{ textDecoration: "none" }}
          >
            {" "}
            <div className="first_product">
              <img
                src="assests/image/Water Level Controller (1).png"
                alt=""
              ></img>
            </div>
          </a>
        </div>
        <div>
          <a
            onClick={(e) => dragging && e.preventDefault()}
            href="/smart_door_lock"
            style={{ textDecoration: "none" }}
            rel="noreferrer"
          >
            <div className="first_product">
              <img src="assests/image/Autrob Smart Lock (1).png" alt=""></img>
            </div>
          </a>
        </div>
        <div>
          <a
            onClick={(e) => dragging && e.preventDefault()}
            href="/hydroponic_automation"
            style={{ textDecoration: "none" }}
          >
            {" "}
            <div className="first_product">
              <img src="assests/image/Cover (1).png" alt=""></img>
            </div>
          </a>
        </div>
      </Slider>
    </>
  );
};

export default ResourcesSlider;
