import React from "react";
import { BsPlayFill } from "react-icons/bs";
import { staggerContainer, fadeIn } from "../../utils/motion";

const Banner = ({ width }) => {
  let iconStyles = {
    color: "#000000",
    fontSize: width > 700 ? "4vw":"8vw",
    position: "relative",
    left: "2.5px",
  };
  return (
    <div
      variants={staggerContainer}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true, amount: 0.25 }}
      className="banners"
    >
      {/* <div variants={fadeIn("right", "tween", 0, 0.1)}> */}
        <div className="first_banner">
          <div className="first_banner_first_heading">
            <h3
              style={{
                fontFamily: "Poppins",
                fontWeight: "500",
                fontSize: width > 700 ? "1.3vw" : "3.5vw",
                letterSpacing: "0.05vw",
                color: "grey",
                marginTop: width > 700 ? "0.5vw" : "2vw",
              }}
            >
              Goal
            </h3>
          </div>
          <div className="first_banner_second_heading">
            <h3>Reviving the Golden Age through pioneering innovation and technological solutions that reshape industries and improve lives globally.</h3>
          </div>
          <div
            className="first_banner_third_heading"
            style={{ marginTop: "1vw" }}
          >
            <h3
              style={{
                fontFamily: "Poppins",
                fontWeight: "500",
                fontSize: width > 700 ? "1.3vw" : "3.5vw",
                letterSpacing: "0.05vw",
                color: "grey",
                marginTop: width > 700 ? "0vw" : "2vw",
              }}
            >
              Vision
            </h3>
            <div className="first_banner_third_heading-2">
              <h3>Rapidly build transformative technology solutions that drive progress and shape the future of industries. </h3>
            </div>
          </div>
          <div   style={{ marginTop: "1vw" }} className="first_banner_fourth_heading">
              
            <h3
              style={{
                fontFamily: "Poppins",
                fontWeight: "500",
                fontSize: width > 700 ? "1.3vw" : "3.5vw",
                letterSpacing: "0.05vw",
                color: "grey",
                marginTop: width > 700 ? "0vw" : "2vw",
              }}
            >
              Mission
            </h3>
            <div className="first_banner_fourth_heading-2">
              <h3>Driving 1000 innovations and inventions that disrupt industries, empower individuals, and create a positive impact on society. </h3>
            </div>
            <div className="first_banner_playbutton">
              <BsPlayFill style={iconStyles} />
            </div>
          </div>
        </div>
      {/* </div> */}
      <div
        variants={fadeIn("left", "tween", 0, 0.1)}
        className="second_banner"
        style={{ position: "relative", bottom: "5vw", left: "3vw" }}
      >
        <div
          style={{
            width: "90%",
            height: "90%",
            position: "absolute",
            borderRadius: "5% 50% 50% 5%",
            zIndex: "10",
            overflow: "hidden",
            
          }}
        >
          <img
            src="assests/edit/Autrob Landing Page (1).jpg"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
              borderRadius: "5% 50% 50% 5%",
            }}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Banner;
